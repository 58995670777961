import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0067 = (props: Props): JSX.Element => {
  const routePram = useRouteParam('/column/details/C0002/')
  const routePram2 = useRouteParam('https://www.kintaicloud.jp/')
  const routePram3 = useRouteParam('https://www.kintaicloud.jp/')
  return (
    <Wrapper>
      <ColumnH1 label="勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは..." />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2023.09.27</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <img
          src="/images/column/details/c0067.jpg"
          alt="キンクラコラム画像67"
        />
        <p>
          勤怠管理と経費精算は、多くの企業にとって煩雑な業務です。紙での申請やExcelを使用する従来の方法は、ミスによる差し戻しなど、効率性を損なうことが多くありました。しかし、最近では
          <span>
            クラウドサービスの普及により、これらの業務を効率的に管理できる新たな解決策
          </span>
          が提供されています。
          <br />
          クラウド勤怠管理システムにより、自動化や決まったフォームへの入力が可能となり、
          <span>従業員の勤怠情報や経費精算がスピーディーかつ正確に入力</span>
          できます。また、勤怠ルールや経費精算ルールの厳守をサポートし、
          <span>企業の業務プロセスを劇的に向上</span>させることができます。
          <br />
          <br />
          そこでこの記事では、クラウドサービスの活用を通じて、勤怠管理と経費精算を効率化する方法について紹介します。
        </p>
        <h1 id="toc1">そもそもクラウド勤怠管理システムとはなにか？</h1>
        <p>
          そもそもクラウド勤怠管理システムとはどのようなものでしょうか？クラウド勤怠管理について詳しく説明します。
        </p>
        <h2 id="toc1-1"> クラウド勤怠管理システムとは</h2>
        <p>
          クラウド勤怠管理システムは、企業の
          <span>従業員の勤怠データや経費精算をリアルタイムで一元管理</span>
          できるWebサービスです。
          <br />
          これにより、従業員の勤怠情報をスピーディーかつ正確に入力でき、勤怠ルールの厳守を促進できます。
          <br />
          <br />
          具体的には、従業員が勤務時間や休暇の申請をオンラインで入力し、上司や人事部門がこれらのデータを承認または管理できる機能を搭載しています。システムによっては、
          <span>
            残業時間超過の自動アラートや、勤務間インターバルの設定など法的事項にも対応
          </span>
          できるものもあります。
          <br />
          労働関連法はよく改定されるため、社労士などの専門家にいつも相談していなければ、気づかないうちに法令違反を起こしてしまうことが多くあります。
          <br />
          勤怠管理システムは、従業員の勤怠ルール徹底だけでなく、会社として
          <span>労働基準法などの法令遵守</span>が可能になる便利なツールです。
          <br />
          <br />
          <br />
          以上が、クラウド勤怠管理システムの概要になります。
          <br />
          次の項目から、導入のメリットや導入する際のポイントを順に解説していきます。
        </p>
        <a href={routePram} className="column-detail-link">
          参照記事：勤怠管理とは？管理をする必要性や管理体制の整備について解説
        </a>
        <h1 id="toc2"> 経費精算機能付きの勤怠管理クラウド導入のメリット</h1>
        <img
          src="/images/column/details/c0067_g01.jpg"
          alt="キンクラコラム画像67"
        />
        <p>
          勤怠管理システムを導入するなら、
          <span>経費精算の機能が付いたクラウド型の勤怠管理システム</span>
          を導入することをお勧めします。その理由について、解説していきます。
        </p>
        <h2 id="toc2-1">コストを低く抑えることができる</h2>
        <p>
          クラウド型の勤怠管理システムは、操作性が高いものも多く、ユーザー数に応じて使用料を支払うためコストを安く抑えることができます。
        </p>
        <h2 id="toc2-2">時間削減と労働力コストの軽減</h2>
        <p>
          勤怠管理や経費精算の業務は、手作業で行う場合、企業の人事や経理部門に多くの時間と手間がかかります。しかし、システムを導入すれば効率化が図られ、
          <span>自動化やデータの集計・確認が容易</span>になります。
          <br />
          また、クラウド型の勤怠管理システムはWebサービスのため、全従業員のPCに専用ソフトウェアをインストールすることなく使用できる点も利点です。
          <br />
          そのため、勤怠管理システムを導入する際に発生する工数を減らし、
          <span>導入時にかかる時間の削減</span>も可能です。
        </p>
        <h2 id="toc2-3">データの一元管理と連携による業務効率化</h2>
        <p>
          経費精算の機能の付いた勤怠管理クラウドならば、データの一元管理が可能です。
          <br />
          従来のExcelや紙ベースでの管理では情報を集約する必要がありましたが、クラウド勤怠管理システムなら
          <span>自動で勤怠情報を集約・集計</span>してくれます。
          <br />
          これにより、データの確認や入力作業が簡単になり、業務効率化が図られます。
        </p>
        <h2 id="toc2-4">クラウドサービスによる手間のないメンテナンス</h2>
        <p>
          クラウドサービスを利用した勤怠管理・経費精算システムは、
          <span>手間のかからないメンテナンス</span>
          が特徴です。サービス提供会社がシステムのセキュリティや更新を行ってくれるため、企業側では特別な対応が不要です。
          <br />
          <br />
          このように、経費精算機能のついた勤怠管理システムを導入することでさまざまなメリットがあります。
        </p>
        <a href={routePram2} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <h1 id="toc3">勤怠管理・経費精算システムを導入する際のポイント</h1>
        <p>
          勤怠管理・経費精算システムのスムーズな導入を実現するために、いくつかポイントがあります。
        </p>
        <h2 id="toc3-1">導入前のステップとポイント</h2>
        <p>
          導入前のステップとして以下のポイントに注意することで、スムーズにシステムを導入することができます。
          <br />
          ・要件定義：企業の目的や課題を明確にして、必要なシステム機能をリストアップ。
          <br />
          ・製品比較：提供されている各システムの機能や価格、サポート内容を検討し、適切な製品を選定。
          <br />
          ・導入プランの策定：導入時期や担当者、費用等を含めた導入プランを策定する。
          <br />
          ・社内体制の整備：導入にあたっての担当者を選定し、社内体制を整える。
          <br />
          以上のポイントを押さえることで、スムーズな導入が可能となります。
        </p>
        <h2 id="toc3-2">運用における注意事項とサポート体制</h2>
        <p>
          システムは導入するだけではなく、導入後の「運用」も重要なフェーズになります。以下に、適切な運用を行うための注意事項や、サポート体制についてのポイントを紹介します。
          <br />
          ・社員への周知：システムの利用方法や運用フローを明確にし、社員に周知することで利用の意義を共有し、最大限活用を促す。
          <br />
          ・セキュリティ対策：パスワード管理など、システム利用時のセキュリティ対策を万全に行う。
          <br />
          ・運用ルールの査定：システム運用に関するルールを策定し、徹底する。
          <br />
          ・業務改善への取り組み：システム導入後も運用を見直し、業務改善に繋げる。
          <br />
          <br />
          以上のポイントを押さえることで、適切な運用を行うことができます。
        </p>
        <h1 id="toc4">まとめ: 勤怠管理クラウドで効率化を実現</h1>
        <p>
          経費精算や勤怠管理の効率化が求められる企業にとって、クラウド勤怠管理システムの導入は大きなメリットをもたらします。
          <br />
          とくに<span>経費精算の機能の付いた勤怠管理システムを導入</span>
          することで、別システムを併用すること無く、業務の効率化を図ることができます。
          <br />
          是非、自社の課題解決に向けて、経費精算機能の付いた勤怠管理クラウドを検討してみてください。
          <br />
          <br />
          もし、システムの選定に迷っているのであれば、
          <span>キンクラでは、全機能が無料で使える「無料トライアル」</span>
          を実施しています。クラウド型の勤怠管理システムの導入を検討しているなら、キンクラで一度お試しをすることもおすすめです。
        </p>
        <a href={routePram3} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0067
